<template>
  <div>


<!-- Modal -->
<div class="modal  fade"  id="InvoiceModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="InvoiceModal">
  <div class="modal-dialog modal-xl ">
    <div class="modal-content  " >
      <div class="container p-2 pb-4" >
          <div class="row ">
          <div class="d-flex col-12 justify-content-end">
               <button
                   @click="closeModal"
          class="btn"
          style=""><i class="fa fa-times" aria-hidden="true"></i></button>
          </div>
          </div>
          <div class="row px-5  " >
              <div class="col-12 ">
                  <p class="TitleHead border-bottom pb-3 m-0">Your Invoices</p>
              </div>

          </div>

          <!-- Invoice Start -->
          <div class=" px-5   InvoiceContainer" >
            <div v-if="Invoices.length">
             <div class="d-flex align-items-center justify-content-between  pb-1 mt-3" v-for="(invoice , index) in Invoices" :key="index" :class="index < Invoices.length -1 ? 'border-bottom' : null">
               <!-- Left Section -->
               <div class="">
                <p class="invoice_name">{{invoice.invoice_name}}</p>
                <p class="invoice_amount">Amount: {{invoice.invoice_amount}} {{invoice.currency}}</p>
                <p class="invoice_completed">{{invoice.invoice_completed}}</p>

               </div>
                <div>
                    <b-icon-file-arrow-down scale="1.5"  style="color:#4D1B7E"></b-icon-file-arrow-down>
                </div>
               <!-- Right Section -->
             </div>
             </div>

             <div v-else class="mt-3">
                <p class="invoice_name text-center">No invoice found</p>
             </div>
          </div>
          <!-- Plans End -->
       </div>

    </div>
  </div>
</div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
data(){
    return{
        Invoices: [
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "May 8 2022",
                 currency:"USD"
            },
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "April 8 2022",
                 currency:"USD"
            },
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "March 8 2022",
                 currency:"USD"
            },
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "May 8 2022",
                 currency:"USD"
            },
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "April 8 2022",
                 currency:"USD"
            },
            {
                invoice_name:"Pro Plan monthly payment",
                invoice_amount: 15,
                invoice_completed : "May 8 2022",
                currency:"USD"
            },
            ]
    }
},
    methods:{
        OpenModal(){

        $('#InvoiceModal').modal('show')
    },
        closeModal(){
            $('#InvoiceModal').modal('hide')
        },
    }
}
</script>

<style scoped>
.modal-content
{
    border-radius: 20px;
        overflow: hidden;
}

.InvoiceContainer{
    min-height:200px;
    max-height:500px;
    overflow: hidden scroll;
    scroll-behavior: smooth;
    height:100%;
}

.TitleHead{
    font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;
color: #4D4950;
}
.border-bottom{

border-bottom:0.3px solid #4D4950aa;
}

.invoice_name{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
letter-spacing: 0.04em;

/* Header */

color: #4D4950;
}

.invoice_amount{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.04em;

/* Template Gray */

color: #535355;
}
.invoice_completed{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.04em;

/* Template Gray */

color: #535355;
}

</style>
